
import { Component, Vue } from "vue-property-decorator";
import SiteFooter from "../components/site-footer.vue";
import HeaderAgentBar from "../components/header_agent-bar.vue";

@Component<DefaultLayout>({
	middleware: "check-auth",

	components: {
		SiteFooter: SiteFooter as any,
		HeaderAgentBar: HeaderAgentBar as any,
	},
})
export default class DefaultLayout extends Vue {}
