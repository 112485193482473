import { render, staticRenderFns } from "./default_fixed-frame.vue?vue&type=template&id=5a4814d9&scoped=true"
import script from "./default_fixed-frame.vue?vue&type=script&lang=ts"
export * from "./default_fixed-frame.vue?vue&type=script&lang=ts"
import style0 from "~/assets/styles/common.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./default_fixed-frame.vue?vue&type=style&index=2&id=5a4814d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4814d9",
  null
  
)

export default component.exports